import { gql } from 'graphql-tag';
import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';

export default gql`
    fragment CenterSummaryFragment on Center {
        path
        title
        slug
        about
        center
    }

    ${ImageSummaryFragment}
`;
